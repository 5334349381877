@import "/node_modules/bootstrap/scss/bootstrap.scss";

$order-values: (-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

@for $i from 1 through 12 {
  .col-#{$i} {
    @container content-wrapper (min-width: 0px) {
      width: ($i / 12) * 100%;
    }
  }
}

@each $order in $order-values {
  @container content-wrapper (min-width: 0px) {
    .order-#{$order} {
      order: #{$order} !important;
    }
  }
}

@each $infix, $breakpoint in $grid-breakpoints {
  @container content-wrapper (min-width: #{$breakpoint}) {
    [class*="col-#{$infix}-"] {
      float: left;
      padding-right: $grid-gutter-width / 2;
      padding-left: $grid-gutter-width / 2;

      @for $i from 1 through 12 {
        &.col-#{$infix}-#{$i} {
          width: ($i / 12) * 100%;
        }
      }

      &.col-#{$infix}-auto {
        width: auto;
      }

      &.col-#{$infix} {
        flex: 1 0 0%;
      }
    }
    @each $order in $order-values {
      .order-#{$infix}-#{$order} {
        order: #{$order} !important;
      }
    }
  }
}
